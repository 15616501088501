
import { Component, Vue } from "vue-property-decorator";
import LayoutDefault from "@/layouts/LayoutDefault.vue";
import ApiService from "@/services/ApiService";
import TitleText from "@/components/TitleText.vue";

@Component({
  components: {
    LayoutDefault,
    TitleText
  }
})
export default class VerifyEmail extends Vue {
  private async mounted() {
    const postData = {
      email: this.$route.query.email,
      token: this.$route.query.token,
      local: true
    };
    const verifyEmail = await ApiService.post(
      `https://0spz8bbss8.execute-api.eu-west-2.amazonaws.com/dev/confirm`,
      postData
    )
      .then(data => {
        if (data.error) {
          this.verificationError = true;
        } else {
          this.verificationError = false;
        }
      })
      .catch(e => console.log(e));
  }

  private verificationError = false;

  private titleText1 = {
    title: "Thank you, your email address has been verified.",
    text: "You may now close this page",
    customClass: "py-md"
  };

  private titleText2 = {
    title: "Sorry! There was an issue verifying your email.",
    text: "Please try again later.",
    customClass: "py-md"
  };
}
